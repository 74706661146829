<template>
  <div class="close-case-container">
    <template v-if="stage != null">
      <div v-if="isOnTreatment">
        <Button id="closeCaseInitiateBtn"
          class="button"
          color="primary"
          :label="$t('close_case')"
          v-if="!closingCase"
          @click="initiateCloseCase"
        />

      <div class="cancel-button-root">
        <Button id="closeCaseCancelBtn"
          class="cancel-button"
          color="secondary"
          :label="$t('cancel')"
          v-if="closingCase"
          @click="cancelCloseCase"
        />
      </div>

        <div class="info" v-if="!closingCase">
          <p class="dark-text">
            {{$t("close_case_warning_on_treatment").replace("DATE", formatDate(endDate))}}
          </p>
        </div>

        <div id="close_case" class="form-horizontal" v-if="closingCase">
          <Form
            id="closeCase"
            key="CloseCase"
            :name="'CloseCase'"
            :isEditing="true"
            :loadFormData="true"
            :submitIcon="'fa fa-check'"
            :get-form-url-params="'idName=PATIENT&id=' + patientId"
            :save-form-url-params="'?episodeId=' + patientId"
          />
        </div>
      </div>

      <div v-else>
        <Button id="closeCaseInitiateReBtn"
          class="button"
          color="primary"
          :label="$t('patient_details_activity_reopen_case')"
          v-if="!reOpeningCase"
          @click="initiateReOpenCase"
        />

        <Button id="closeCaseSubmitReBtn"
          class="button"
          color="primary"
          :label="$t('submit')"
          v-if="reOpeningCase"
          :disabled="submittingReOpenCase"
          @click="submitReOpenCase"
        />

        <Button id="closeCaseCancelReBtn"
          class="button"
          color="secondary"
          :label="$t('cancel')"
          v-if="reOpeningCase"
          :disabled="submittingReOpenCase"
          @click="cancelReOpenCase"
        />

        <div class="info" v-if="!reOpeningCase">
          <p class="dark-text">
            {{$t("_patient_not_on_treatment_end_date_was")}}
            <span class="endDate">
          {{formatDate(endDate)}}
        </span>
            . {{$t("click_to_reopen_case")}}
          </p>

          <p class="dark-text">
            {{$t("_the_treatment_outcome_set_for_this_patient_is")}}
            <span class="treatment-outcome">
          {{treatmentOutcome}}
        </span>
          </p>
        </div>

        <form id="reopenCaseForm" class="form-horizontal" v-if="reOpeningCase">
          <div class="form-group">
            <div class="reopen-case-inputs">
              <DatePicker
                name="newEndDate"
                id="endDateReopenCase"
                label="New End Date"
                v-model="reOpenEndDate"
                :orientation="'v'"
                :to="caseReopenDateRestrictionTillToday"
              />
            </div>
          </div>

          <div class="form-group reopen-case-inputs" v-if="monitoringMethod === 'MERM'">
            <Select
              :is-disabled="submittingReOpenCase"
              :all-options="mermOptions"
              label="evriMED(MERM) Serial Number"
              name="merm"
              v-model="imei"
              :orientation="'v'"
            />
          </div>

          <div class="form-group">
            <div class="col-md-12">
              <TextArea
                name="reOpenNote"
                :placeholderText="$t('type_your_note_here') + '...'"
                label=""
                :is-disabled="submittingReOpenCase"
                v-model="reOpenNote"
                :orientation="'v'"
              />
            </div>
          </div>
        </form>
      </div>

    </template>
  </div>
</template>

<script>
import Form from '../../../../shared/components/Form'
import Button from '../../../../shared/components/Button'
import EventBus from '@/app/shared/event-bus.js'
import DatePicker from '../../../../shared/components/DatePicker'
import TextArea from '../../../../shared/components/TextArea'
import Select from '../../../../shared/components/Select'
import { mapState, mapActions } from 'vuex'
import { defaultToast, ToastType } from '../../../../../utils/toastUtils'
import { getFormattedDate } from '../../../../../utils/utils'

export default {
  name: 'CloseCase',
  data: self => ({
    closingCase: false,
    reOpeningCase: false,
    reOpenEndDate: null,
    reOpenNote: '',
    imei: null,
    submittingReOpenCase: false
  }),
  created () {
    EventBus.$on('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
    const reOpenEndDate = new Date()
    reOpenEndDate.setDate(reOpenEndDate.getDate() + 1)
    this.reOpenEndDate = reOpenEndDate
  },
  destroyed () {
    EventBus.$off('FORM_SAVE_SUCCESS', this.formSavedSuccessfully)
  },
  components: {
    Form,
    Button,
    DatePicker,
    TextArea,
    Select
  },
  methods: {
    ...mapActions('Patient', ['reOpenCase']),
    formSavedSuccessfully (formName) {
      location.reload()
    },
    initiateCloseCase () {
      this.closingCase = true
    },
    cancelCloseCase () {
      this.closingCase = false
    },
    initiateReOpenCase () {
      this.reOpeningCase = true
    },
    cancelReOpenCase () {
      this.reOpeningCase = false
    },
    async submitReOpenCase () {
      const reOpenEndDate = new Date(this.reOpenEndDate)
      const reOpenEndDateString = getFormattedDate(reOpenEndDate)

      const payload = {
        episodeId: this.patientId,
        endDate: reOpenEndDateString,
        noteText: this.reOpenNote,
        imei: ((this.imei) ? this.imei.Value : null)
      }

      this.submittingReOpenCase = true

      const response = await this.reOpenCase(payload)

      if (response) {
        defaultToast(ToastType.Success, 'Reopened successfully!')
        location.reload()
      } else {
        defaultToast(ToastType.Error, 'Failed to reopen case')
      }

      this.submittingReOpenCase = false
    },
    formatDate (date) {
      return getFormattedDate(date)
    }
  },
  computed: {
    ...mapState('Patient', ['patientId', 'stage', 'endDate', 'treatmentOutcome', 'availableMerms', 'monitoringMethod']),
    isOnTreatment () {
      return this.stage === 'ON_TREATMENT'
    },
    mermOptions () {
      return this.availableMerms.map(imei => ({
        Key: imei,
        Value: imei
      }))
    },
    caseReopenDateRestrictionTillToday () {
      const currentDate = new Date()
      return currentDate
    }
  }
}
</script>

<style scoped>

.close-case-container {
  padding: 20px;
  background-color: var(--background-color-primary-light-dark);
}

.treatment-outcome {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 25%);
}

.info {
  max-width: 75%;
}

p {
  margin: 15px;
}

.button {
  float: right;
  margin: 0 5px !important;
}

* {
  margin: revert;
  box-sizing: unset;
}

.cancel-button-root {
  display: flow-root;
}

.cancel-button {
  display: inline-block;
  float: right;
}

.reopen-case-inputs {
  max-width: 300px;
  padding: 12px;
}

</style>
